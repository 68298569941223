<template>
    <svg
        v-if="isSolid"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        class="fill-current"
    >
        <path
            d="M32 20v-16c0-1.061-0.421-2.078-1.172-2.828s-1.768-1.172-2.828-1.172h-24c-1.061 0-2.078 0.421-2.828 1.172s-1.172 1.768-1.172 2.828v16c0 1.061 0.421 2.078 1.172 2.828s1.768 1.172 2.828 1.172h4v8l10-8h10c1.061 0 2.078-0.421 2.828-1.172s1.172-1.768 1.172-2.828zM6 10h4v0.016h12v-0.016h4v4h-4v-3.983h-3.983v3.999h-4v-3.999h-4.017v3.983h-4v-4z"
        ></path>
    </svg>
    <svg
        v-else
        viewBox="0 0 20 19"
        class="fill-current"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V11C2 11.2652 2.10536 11.5196 2.29289 11.7071C2.48043 11.8946 2.73478 12 3 12H7C7.55228 12 8 12.4477 8 13V15.5858L11.2929 12.2929C11.4804 12.1054 11.7348 12 12 12H17C17.2652 12 17.5196 11.8946 17.7071 11.7071C17.8946 11.5196 18 11.2652 18 11V3C18 2.73478 17.8946 2.48043 17.7071 2.29289C17.5196 2.10536 17.2652 2 17 2H3ZM0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0H17C17.7957 0 18.5587 0.31607 19.1213 0.87868C19.6839 1.44129 20 2.20435 20 3V11C20 11.7957 19.6839 12.5587 19.1213 13.1213C18.5587 13.6839 17.7957 14 17 14H12.4142L7.70711 18.7071C7.42111 18.9931 6.99099 19.0787 6.61732 18.9239C6.24364 18.7691 6 18.4045 6 18V14H3C2.20435 14 1.44129 13.6839 0.87868 13.1213C0.31607 12.5587 0 11.7956 0 11V3C0 2.20435 0.31607 1.44129 0.87868 0.87868ZM5 7C5 6.44772 5.44772 6 6 6H6.01C6.56228 6 7.01 6.44772 7.01 7C7.01 7.55228 6.56228 8 6.01 8H6C5.44772 8 5 7.55228 5 7ZM9 7C9 6.44772 9.44771 6 10 6H10.01C10.5623 6 11.01 6.44772 11.01 7C11.01 7.55228 10.5623 8 10.01 8H10C9.44771 8 9 7.55228 9 7ZM13 7C13 6.44772 13.4477 6 14 6H14.01C14.5623 6 15.01 6.44772 15.01 7C15.01 7.55228 14.5623 8 14.01 8H14C13.4477 8 13 7.55228 13 7Z"
        />
    </svg>
</template>

<script>
export default {
  name: "IconChatAlt",
  props: {
    isSolid: {
      type: Boolean,
      default: false
    }
  }
};
</script>
